import { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './Loader.less';

class Loader extends Component {
  constructor(props) {
    super(props);

    this.showLoader = this.showLoader.bind(this);

    this.state = {
      timeout: null,
      visible: !props.delay,
    };
  }

  componentDidMount() {
    const { delay } = this.props;
    if (delay) {
      this.setDelayTimeout();
    }
  }

  componentWillUnmount() {
    const { timeout } = this.state;
    this.setState({ timeout: null });
    clearTimeout(timeout);
  }

  setDelayTimeout() {
    this.setState({
      timeout: setTimeout(this.showLoader, 500),
    });
  }

  showLoader() {
    this.setState({
      visible: true,
    });
  }

  render() {
    const { className } = this.props;
    const { visible } = this.state;

    return (
      <div
        className={cx('zola-loader', className)}
        style={{ visibility: visible ? 'visible' : 'hidden' }}
      >
        <div className="petal" />
        <div className="petal" />
        <div className="petal" />
        <div className="petal" />
        <div className="petal" />
        <div className="petal" />
        <div className="petal" />
        <div className="petal" />
      </div>
    );
  }
}

Loader.propTypes = {
  delay: PropTypes.bool,
  className: PropTypes.string,
};

Loader.defaultProps = {
  delay: true,
};

export default Loader;
