import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { hide } from '../../../actions/NotificationActions';
import Notification from './Notification';

/**
 * Simple Notification Service for Zola Weddings
 * TODO: Expand upon this as needed
 */
class Notifications extends Component {
  constructor(props) {
    super(props);

    this.remove = this.remove.bind(this);
  }

  remove(id, timeout) {
    const { onRemoveNotification } = this.props;
    setTimeout(() => onRemoveNotification(id), timeout);
  }

  render() {
    const { notifications } = this.props;

    return (
      <TransitionGroup className="notifications-container">
        {notifications.map((notification) => (
          <Notification key={notification.id} notification={notification} onRemove={this.remove} />
        ))}
      </TransitionGroup>
    );
  }
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      autoDismiss: PropTypes.number.isRequired,
      dismissible: PropTypes.bool.isRequired,
      onClose: PropTypes.func,
    })
  ),
  onRemoveNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  onRemoveNotification: (id) => dispatch(hide(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
